<template src="./create-quiz.html"></template>
<script>
import createPopupQuiz from "./create-popup-quiz/create-popup-quiz";
import quizService from "../../services/quiz-service";
import tagService from "../../services/tag-service";
import sessionService from "../../services/session-service";
import storageService from "../../services/storage-service";
import videoService from "../../services/video-service";
import validatorService from "../../services/validator-service";
import VueTagsInput from "@johmun/vue-tags-input";
import ClickOutside from "vue-click-outside";
import VueJsonToCsv from "vue-json-to-csv";
import Vue from "vue";
import moment from "moment";
import {
  readQuestionFromCSV,
  readFileContent,
  csvSocketinit,
} from "../../services/csvService";

function Stack() {
  this.stac = new Array();
  this.pop = function () {
    return this.stac.pop();
  };
  this.push = function (item) {
    this.stac.push(item);
  };
}
export default {
  name: "app-create-quiz",
  components: {
    "app-create-popup-quiz": createPopupQuiz,
    "vue-json-to-csv": VueJsonToCsv,
    VueTagsInput,
  },
  directives: {
    ClickOutside,
  },
  data: function () {
    return {
      disable1: true,
      instructor_id: -1,
      editMode: false,
      createdQuizId: "",
      previousQuizId: "",
      quiz: { result_delay_in_hours: 0, ai_model: 'gpt-3.5' },
      quizType: "full",
      stack: new Stack(),
      moduleOrChapter: 0,
      moduleOrChapterId: 0,
      percentCompleted: 0,
      uploadingFile: false,
      newVersion: false,
      automaticQuiz: false,
      dataValidated: false,
      activeAiQuiz: false,
      chevronListBox: "",
      windowType: 1,
      isPopUp: false,
      popupType: 0,
      isDelayInResult: false,
      isCourseFocused: false,
      isModuleFocused: false,
      isChapterFocused: false,
      isVideoFocused: false,
      isSessionFocused: false,
      isFacultyFocused: false,
      courseNotFound: false,

      selectedCourse: { name: "Click here to Select Course" },
      selectedModule: { name: "Click here to Select Module" },
      selectedChapter: { name: "Click here to Select Chapter" },
      selectedVideo: { name: "Click here to Select Video" },

      session: { name: "Default Session", description: "Default Session", start_time: moment().format(), end_time: moment().add(6,'M').format() },
      sessionOld: { name: "", description: "", start_time: "", end_time: "" },
      itemListCourses: [],
      itemListModules: [],
      itemListChapters: [],
      itemListVideos: [],
      itemListSessions: [],
      allSessions: [],

      inputValueCourse: "",
      inputValueModule: "",
      inputValueChapter: "",
      inputValueVideo: "",
      inputValueSession: "",

      apiLoadedCourse: false,
      apiLoadedModule: false,
      apiLoadedChapter: false,
      apiLoadedVideo: false,
      apiLoadedSession: false,
      uploadingCSV: false,

      loadingCourse: false,
      loadingModule: false,
      loadingChapter: false,
      loadingVideo: false,
      faculty: "",
      faculties: [],
      selectedFaculties: [],
      selectedFacultiesForAutoQuiz: [],
      selectedTagsForAutoQuiz: [],
      difficulty_level: "Easy",
      tags: [],
      tag: "",
      questionsCount: "",
      csvFileName: "",
      topics: [],
      callcheck: {
        AllFaculties: false,
        ModulesByCourseId: false,
        video: false,
        GeneralSessionByQuizId: false,
      },
      warnings: [],
      errors: [],
      questions: [],
      validation_errors: {},
      sampleCSV: process.env.VUE_APP_DOWNLOAD_empty_CSV,
      errorVideo: false,
    };
  },
  props: {
    quizData: {
      type: Object,
    },
  },
  watch: {
    quiz: {
      handler(quiz) {
        if (quiz.passing_percentage && quiz.passing_percentage > 100) {
          this.quiz.passing_percentage = null;
        }
        if (quiz.mins && quiz.mins > 59) {
          this.quiz.mins = null;
        }
      },
      deep: true,
    },

    windowType: function() {
        this.$parent.popCpTop();
    },
  },
  mounted() {
    this.atCreation();
    this.popupItem = this.$el;
  },
  computed: {
    filteredItems() {
      return this.faculties.filter((i) => {
        return i.text.toLowerCase().indexOf(this.faculty.toLowerCase()) !== -1;
      });
    },
    filteredItemsForTags() {
      return this.tags.filter((i) => {
        return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1;
      });
    },
  },
  created() {
    this.instructor_id = storageService.get("uid");
    // this.getAllFacultiesToLoad();
    this.getAllNicheTags();
    this.getAllTags();
    this.getCoursesByInstructorId();
  },
  methods: {
    secondsToHms(seconds) {
      seconds = Number(seconds);
      const h = Math.floor(seconds / 3600);
      const m = Math.floor((seconds % 3600) / 60);
      const s = Math.floor((seconds % 3600) % 60);
      return { h, m, s };
    },
    hideCourse() {
      this.isCourseFocused = false;
    },
    hideModule() {
      this.isModuleFocused = false;
    },
    hideChapter() {
      this.isChapterFocused = false;
    },
    hideVideo() {
      this.isVideoFocused = false;
    },
    hideFaculty() {
      this.isFacultyFocused = false;
    },
    createNewVersion() {
      this.newVersion = true;
      this.firstWindowHandler(6);
    },
    async emptyCsv() {
      try {
        let link = process.env.VUE_APP_DOWNLOAD_empty_CSV;
        window.location.href = link;
        if (link) {
          this.$toast("File Downloaded");
        }
      } catch (error) {
        this.$toast.error("Error in file download");
      }
    },
    async saveSession(data, type) {
      try {
        let response;
        if (this.editMode) {
          if (this.newVersion) {
            data.quiz_id = this.quiz._id;
            response = await sessionService.createSession(data);
          } else {
            data._id = this.session._id;
            data.questionsRequired = true;
            response = await sessionService.updateSession(data);
          }
        } else {
          response = await sessionService.createSession(data);
        }
        if (response) {
          if (response.data.status === "success") {
            this.session = response.data.data.session;
            if (this.editMode) {
              this.$toast.info("Quiz Updated Successfully");
              if (
                response.data.data.questions &&
                response.data.data.questions.length
              ) {
                this.questions = response.data.data.questions;
              }
              if (this.questions && this.questions.length) {
                if (this.quizType !== "pop") {
                  const routeData = {
                    name: "edit-quiz-questions",
                    params: { questions: this.questions, quiz: this.quiz },
                    query: {
                      quizId: this.createdQuizId,
                      quizType: this.quizType,
                      type: this.moduleOrChapter,
                      id: this.moduleOrChapterId,
                      instructorId: this.instructor_id,
                      ai: this.quiz.ai_quiz_active,
                    },
                  };
                  this.$router.push(routeData);
                } else {
                  this.windowType = 2;
                }
              } else {
                if (this.quizType === "pop") {
                  this.windowType = 2;
                } else {
                  this.windowType = type;
                }
              }
            } else {
              this.$toast.info("Quiz Created Successfully");
              if (this.quizType === "pop") {
                this.windowType = 2;
              } else {
                this.windowType = type;
              }
            }
          } else {
            this.$toast.warning(response.data.message);
          }
        }
      } catch (error) {
        if (error.response) {
          this.$toast.error(error.response.data.errors[0].messages[0]);
        } else if (error.message) {
          this.$toast.error(error.message);
        } else {
          this.$toast.error(error);
        }
      }
    },
    
    continuePopQuiz() {
      this.firstWindowHandler(6);
      // this.$parent.popCpTop();
    },
    async getGeneralSessionByQuizId(id) {
      try {
        let response = await sessionService.getGeneralSessionByQuizId(id);
        if (response) {
          this.callcheck.GeneralSessionByQuizId = true;
          if (this.check() === true) {
            this.dataValidated = false;
          }
          this.session = response.data.data.session;
          this.session.start_time = moment(this.session.start_time).format(
            "YYYY-MM-DDThh:mm"
          );
          this.session.end_time = moment(this.session.end_time).format(
            "YYYY-MM-DDThh:mm"
          );
        }
      } catch (error) {
        if (error.response) {
          this.$toast.error(error.response.data.message);
        } else if (error.message) {
          this.$toast.error(error.message);
        } else {
          this.$toast.error(error);
        }
      }
    },
    async getAutoGeneratedQuiz() {
      try {
        let facIds = [];
        this.selectedFacultiesForAutoQuiz.forEach((faculty) => {
          facIds.push(faculty.id);
        });
        let tagsNames = [];
        if (this.selectedTagsForAutoQuiz.length) {
          this.selectedTagsForAutoQuiz.forEach((tag) => {
            tagsNames.push(tag.text);
          });
        }
        tagsNames.push(this.difficulty_level);
        let data = {
          tagNames: tagsNames,
          facultyIds: facIds,
          questionsCount: this.questionsCount,
        };
        const fields = ["questionsCount"];
        let isDataValidated = await validatorService.validate(fields, data);
        if (isDataValidated) {
          throw new Error(isDataValidated);
        } else {
          try {
            const response = await quizService.getAutoGeneratedQuiz(data);
            if (response) {
              if (response.data.status === "success") {
                if (response.data.data.questions.length) {
                  this.questions = JSON.stringify(response.data.data.questions);
                  this.questions = JSON.parse(this.questions);
                  let allTags = [];
                  response.data.data.questions.forEach((question) => {
                    question.tags.forEach((tag, i) => {
                      if (tag.tag_type === "general") {
                        allTags.push(tag.tag_name);
                        question.tags[i] = tag.tag_name;
                      }
                    });
                  });
                  allTags = [...new Set(allTags)];
                  this.topics = [];
                  allTags.forEach((tag) => {
                    let count = 0;
                    response.data.data.questions.forEach((question) => {
                      let value = question.tags.includes(tag);

                      if (value) {
                        count++;
                      }
                    });
                    this.topics.push({ name: tag, count: count });
                  });
                  this.firstWindowHandler(88);
                } else {
                  this.$toast.warning("No Questions found under this filter");
                }
              } else {
                this.$toast.info(response.data.message);
              }
            }
          } catch (error) {
            if (error.response) {
              this.$toast.error(error.response.data.message);
            } else if (error.message) {
              this.$toast.error(error.message);
            } else {
              this.$toast.error(error);
            }
          }
        }
      } catch (error) {
        if (error.message) {
          this.$toast.error(error.message);
        } else {
          this.$toast.error(error);
        }
      }
    },
    chevronListBoxHandler() {
      this.chevronListBox = !this.chevronListBox;
    },
    getCSVUploadResults(res) {
      this.warnings = [];
      this.errors = [];
      this.questions = res.data.data.questions;
      res.data.data.warnings.forEach((element) => {
        let data = element.split("#");
        if (data[0].toString().trim() === "warning") {
          this.warnings.push({ no: data[2], warning: data[1] });
        } else {
          this.errors.push({ no: data[2], error: data[1] });
        }
      });
    },
    async fileChooser() {
      try {
        this.uploadingCSV = true;
        var input = document.createElement("input");
        input.type = "file";
        input.accept = ".csv";
        input.onchange = this.inputChangeHandler;
        input.click();
      } catch (error) {
        if (error.message) {
          this.$toast.error(error.message);
        } else {
          this.$toast.error(error);
        }
      }
    },
    firstWindowHandler(type) {
      if (type === 4) {
        this.automaticQuiz = true;
        this.selectedFacultiesForAutoQuiz = [...this.selectedFaculties];
      }
      if (type === 6) {
        this.createQuiz(type);
      } else if (type === 77) {
        this.fileChooser();
      } else {
        this.windowType = type;
      }
    },
    backMethod() {
      if (this.$route.query.editType) {
        this.$router.replace({ query: {} });
      }
      this.$parent.popupClose();
    },
    async atCreation() {
      Object.keys(this.callcheck).forEach((element) => {
        this.callcheck[element] = false;
      });
      if (this.quizData) {
        this.editMode = true;
        if (this.editMode === true) {
          this.dataValidated = true;
        }
        const updatedQuiz = await quizService.getQuizUpdatedVersion(
          this.quizData._id
        );
        if (updatedQuiz && updatedQuiz.data.data.quiz) {
          this.quiz = updatedQuiz.data.data.quiz;
          if(!this.quiz.ai_model) {
            this.quiz.ai_model = 'gpt-3.5'
          }
          this.previousQuizId = this.quiz.session_id;
        } else {
          this.quiz = this.quizData;
        }
        // this.getAllFaculties();
        this.getAllNicheTags();
        this.selectedCourse = this.quiz.course;
        this.selectedModule = this.quiz.module;
        this.selectedChapter = this.quiz.chapter;
        this.selectedVideo = this.quiz.video;
        if (this.quiz.result_delay_in_hours > 0) {
          this.isDelayInResult = true;
        } else {
          this.isDelayInResult = false;
        }
        if (this.selectedModule.id) {
          this.getModulesByCourseId(this.selectedCourse.id);
          this.moduleOrChapter = 1;
          this.moduleOrChapterId = this.selectedModule.id;
          this.getVideos();
        }
        if (this.selectedChapter?.id) {
          this.getChapterByModuleId(this.selectedModule.id);
          this.moduleOrChapter = 2;
          this.moduleOrChapterId = this.selectedCourse.id;
          this.getVideos();
        }
        if (this.quiz.duration) {
          const time = this.secondsToHms(this.quiz.duration);
          this.quiz.hours = time.h;
          this.quiz.mins = time.m;
        }
        this.quizType = this.quiz.quiz_type;
        this.getGeneralSessionByQuizId(this.quiz._id);
      }
    },
    backBtn(wintype) {
      if (wintype == 6) {
        this.editMode = true;
        this.firstWindowHandler(1);
      } else if (wintype == 4, wintype == 3) {
        this.windowType = 6;
      } else {
        this.firstWindowHandler(1);
      }
      this.atCreation();
      this.dataValidated = false;
    },
    popUpQuizHandler(quizType) {
      this.quizType = quizType;
      if (this.quizType === "pop") {
        this.isPopUp = true;
      } else {
        this.isPopUp = false;
      }
    },
    retry() {
      this.getCoursesByInstructorId();
    },
    async getCoursesByInstructorId(type) {
      try {
        this.courseNotFound = false;
        this.loadingCourse = true;
        const response = await quizService.getCoursesByInstructorId(
          this.instructor_id
        );
        if (response) {
          this.loadingCourse = false;
          let courses = [];
          if (response.data.data) {
            response.data.data.forEach((course) => {
              course.courses.forEach((crs, index) => {
                crs.value = crs.id;
                courses.push(crs);
              });
            });
          }
          this.itemListCourses = courses;
          this.apiLoadedCourse = true;
        }
      } catch (error) {
        this.loadingCourse = false;
        this.courseNotFound = true;
        if (error.message) {
          this.$toast.error(error.message);
        } else {
          this.$toast.error(error);
        }
      }
    },
    clearCourse() {
      if (this.selectedCourse) {
        this.selectedCourse = "";
        this.selectedModule = "";
        this.selectedChapter = "";
        this.selectedVideo = "";
        this.apiLoadedModule = false;
        this.apiLoadedVideo = false;
        this.apiLoadedChapter = false;
      }
    },
    clearModule() {
      if (this.selectedModule) {
        this.selectedModule = "";
        this.selectedVideo = "";
        this.selectedChapter = "";
        this.apiLoadedVideo = false;
        this.apiLoadedChapter = false;
      }
    },
    clearVideo() {
      if (this.selectedVideo) {
        this.selectedVideo = "";
      }
    },
    async getModulesByCourseId(id) {
      try {
        this.apiLoadedModule = false;
        this.apiLoadedVideo = false;
        this.loadingModule = true;
        const response = await quizService.getModulesByCourseId(id);
        if (response) {
          this.callcheck.ModulesByCourseId = true;
          if (this.check() === true) {
            this.dataValidated = false;
          }
          this.loadingModule = false;
          if (response.data.modules.length > 0) {
            let modules = [];
            response.data.modules.forEach((mdl, index) => {
              mdl.value = mdl.id;
              modules.push(mdl);
            });
            this.itemListModules = modules;
            this.apiLoadedModule = true;
          }
        }
      } catch (error) {
        this.apiLoadedModule = true;
        this.loadingModule = false;
        if (error.message) {
          this.$toast.error(error.message);
        } else {
          this.$toast.error(error);
        }
      }
    },
    async getChapterByModuleId(id) {
      try {
        this.apiLoadedChapter = false;
        this.loadingChapter = true;
        const response = await quizService.getChapterByModuleId(id);
        if (response) {
          if (response.data.chapters.length > 0) {
            this.loadingChapter = false;
            let chapters = [];
            response.data.chapters.forEach((chp, index) => {
              chp.value = chp.id;
              chapters.push(chp);
            });
            this.itemListChapters = chapters;
            this.apiLoadedChapter = true;
          } else {
            this.getVideos();
          }
        } else {
          this.getVideos();
        }
      } catch (error) {
        this.apiLoadedChapter = true;
        this.loadingChapter = false;
        this.getVideos();
        if (error.message) {
          this.$toast.error(error.message);
        } else {
          this.$toast.error(error);
        }
      }
    },
    async getVideos() {
      this.loadingVideo = true;
      try {
        let params = {
          id: this.moduleOrChapterId,
          instructor_id: this.instructor_id,
          type: this.moduleOrChapter,
        };
        let response = await videoService.getVideos(params);
        this.loadingChapter = false;
        if (response) {
          this.callcheck.video = true;
          if (this.check() === true) {
            this.dataValidated = false;
          }
          if (response.data.success && response.data.videos.length) {
            this.itemListVideos = response.data.videos;
            this.apiLoadedVideo = true;
          } else {
            this.itemListVideos = [];
          }
        }
      } catch (error) {
        this.loadingChapter = false;
        this.apiLoadedVideo = true;
        console.log(error);
      }
      this.loadingVideo = false;
    },
    async getAllFaculties() {
      const response = await quizService.getAllfaculties();
      if (response) {
        this.callcheck.AllFaculties = true;
        if (this.check() === true) {
          this.dataValidated = false;
        }
        this.faculties = [];
        response.data.data.faculties.forEach((fac) => {
          this.faculties.push({ id: fac._id, text: fac.faculty_name });
          if (this.editMode && this.quiz.faculties?.length) {
            const found = this.quiz.faculties.find(
              (element) => element === fac._id
            );
            if (found) {
              // this.selectedFaculties.push({
              //   id: fac._id,
              //   text: fac.faculty_name,
              // });
            }
          }
        });
      }
    },
    async getAllNicheTags() {
      const response = await tagService.getAllNicheTags();
      if (response) {
        this.callcheck.AllFaculties = true;
        if (this.check() === true) {
          this.dataValidated = false;
        }
        this.faculties = [];
        if (this.editMode && this.quiz.tags?.length) {
            this.selectedFaculties = this.quiz.tags;
          }
        response.data.courseTags.forEach((fac) => {
          this.faculties.push({ id: fac.id, text: fac.name });
        });
      }
    },
    async getAllFacultiesToLoad() {
      const response = await quizService.getAllfaculties();
      if (response) {
        this.faculties = [];
        response.data.data.faculties.forEach((fac) => {
          this.faculties.push({ id: fac._id, text: fac.faculty_name });
        });
      }
    },
    async getAllTags() {
      const response = await tagService.getAllTags();
      if (response) {
        this.tags = [];
        response.data.data.tag.forEach((elm) => {
          this.tags.push({ id: elm._id, text: elm.tag_name });
        });
      }
    },
    async createQuiz(type) {
      try {
        this.dataValidated = true;
        let hours = this.quiz.hours || 0;
        hours = hours * 60 * 60;
        let minutes = this.quiz.mins || 0;
        minutes = minutes * 60;
        const seconds = hours + minutes;
        const quizData = {
          quiz_type: this.quizType,
          name: this.quiz.name,
          ai_quiz_active: this.quiz.ai_quiz_active,
          description: this.quiz.description,
          instructor_id: this.instructor_id,
          is_published: false,
          created_at: this.quiz.created_at,
          chapter_id: this.selectedChapter?.value || null,
          video_id: this.selectedVideo.id || null,
          passing_percentage: this.quiz.passing_percentage,
          result_delay_in_hours: this.quiz.result_delay_in_hours,
          ai_model: this.quiz.ai_model,

          duration: seconds,
        };
        const fields = [
          "quiz_type",
          "name",
          "description",
          "duration",
          "passing_percentage",
          "session_name",
          "session_description",
          "start_time",
          "end_time",
        ];
        console.log(this.selectedCourse);
        if (this.selectedCourse.value) {
          quizData.course_id = this.selectedCourse.value || null;
          quizData.module_id = this.selectedModule.value || null;
          fields.push("course_id");
          fields.push("module_id");
        }
        if (this.selectedCourse.id) {
          quizData.course_id = this.selectedCourse.id || null;
          quizData.module_id = this.selectedModule.id || null;
          fields.push("course_id");
          fields.push("module_id");
        }
        let isDataValidated = await validatorService.validatorExtensive(
          fields,
          {
            ...quizData,
            session_name: this.session.name,
            session_description: this.session.description,
            start_time: this.session.start_time,
            end_time: this.session.end_time,
          }
        );
        if (isDataValidated) {
          throw new Error(isDataValidated);
        } else {
          let facultyIds = [];
          if (this.isDelayInResult) {
            if (this.quiz.result_delay_in_hours > 0) {
              this.result_delay_in_hours = this.quiz.result_delay_in_hours;
              //throw new Error("please select the radio button to select time");
            }
          } else {
            this.result_delay_in_hours = this.quiz.result_delay_in_hours;
          }

          if (this.selectedFaculties.length) {
            if (this.quizType === "pop" && !this.selectedVideo.id) {
              this.errorVideo = true;
              console.log('hhhhhhhhhhhhhhhhh', this.errorVideo)
              throw new Error("Please select a video");
              
            }
            let sessionData = {};
            sessionData = {
              name: this.session.name,
              description: this.session.description,
              start_time: this.session.start_time,
              end_time: this.session.end_time,
              instructor_id: this.instructor_id,
              type: quizData.course_id ? "general" : "global",
              video_id: quizData.video_id,
              course_id: quizData.course_id,
              module_id: quizData.module_id,
              chapter_id: quizData.chapter_id || "",
            };
            let v_session_data = {
              session_name: this.session.name,
              session_description: this.session.description,
              start_time: this.session.start_time,
              end_time: this.session.end_time,
            };
            const sessionFields = [
              "session_name",
              "session_description",
              "start_time",
              "end_time",
            ];
            let isSessionDataValidated =
              await validatorService.validatorExtensive(
                sessionFields,
                v_session_data
              );
            if (isSessionDataValidated) {
              throw new Error(isSessionDataValidated);
            }
            if (
              moment(this.session.start_time) > moment(this.session.end_time)
            ) {
              throw new Error("Ending time must be greater than starting time");
            }
            this.selectedFaculties.forEach((element) => {
              facultyIds.push(element.id);
            });
            // quizData._id=this.quiz._id;
            quizData.tags = this.selectedFaculties;
            quizData.course = this.selectedCourse;
            quizData.module = this.selectedModule;
            quizData.chapter = this.selectedChapter;
            quizData.video = this.selectedVideo;
            let response;
            if (this.editMode) {
              if (this.newVersion) {
                quizData.quiz_id = this.quiz._id;
                response = await quizService.createNewVersion({
                  quiz: quizData,
                });
              } else {
                quizData.quiz_id = this.quiz._id;
                response = await quizService.updateQuiz(quizData);
              }
            } else {
              response = await quizService.createQuiz(quizData);
            }
            if (response) {
              if (response.data.status === "success") {
                if (response.data.data.newVersionCheck) {
                  this.windowType = 22;
                } else {
                  if (response.data.data.quiz._id) {
                    if (
                      response.data.data.questions &&
                      response.data.data.questions.length
                    ) {
                      this.questions = response.data.data.questions;
                    }
                    this.quiz = response.data.data.quiz;
                    this.createdQuizId = response.data.data.quiz._id;
                    sessionData.quiz_id = this.createdQuizId;
                    this.saveSession(sessionData, type);
                  }
                }
              } else {
                this.$toast.info(response.data.message);
              }
            }
          } else {
            throw new Error("faculty: Atleast one faculty should be selected");
          }
        }
      } catch (error) {
        this.dataValidated = false;
        if (error.message) {
          if (error.message.includes(":")) {
            this.$toast.error(error.message.split(":")[1]);
            this.validation_errors = {};
            this.validation_errors[error.message.split(":")[0]] =
              error.message.split(":")[1];
            console.log(this.validation_errors);
          } else {
            this.$toast.error(error.message);
          }
        } else if (typeof error == "object") {
          if (Object.keys(error).length <= 2) {
            Object.keys(error).forEach((err) => {
              if (error[err] === "name is required") {
                this.$toast.error("Title is required");
              } else {
                this.$toast.error(error[err]);
              }
            });
          } else this.$toast.error("Missing or invalid fields");
          this.validation_errors = { ...error };
          if (this.validation_errors.name) {
            this.validation_errors.name = "Title is required";
          }
        } else if (error.response.data.message == "Validation Error") {
          this.$toast.error(error.response.data.errors[0].messages[0]);
        }
        // if (error.message) {
        //   this.$toast.error(error.message);
        // } else {
        //   this.$toast.error(error);
        // }
      }
    },
    openClose(type) {
      if (type === "module") {
        if (this.isModuleFocused) {
          this.isModuleFocused = false;
        } else {
          this.isModuleFocused = true;
        }
      } else if (type === "chapter") {
        if (this.isChapterFocused) {
          this.isChapterFocused = false;
        } else {
          this.isChapterFocused = true;
        }
      } else if (type === "video") {
        if (this.isVideoFocused) {
          this.isVideoFocused = false;
        } else {
          this.isVideoFocused = true;
        }
      }
    },
    selectCourse(theItem) {
      if (this.selectedCourse.name !== theItem.name) {
        this.clearModule();
      }
      this.selectedCourse = theItem;
      this.inputValueCourse = "";
      this.isCourseFocused = false;
      this.getModulesByCourseId(this.selectedCourse.value);
    },
    selectModule(theItem) {
       if(this.selectedModule.name !== theItem.name){
         this.clearVideo();
       }
      this.apiLoadedVideo = false;
      this.selectedModule = theItem;
      this.inputValueModule = "";
      this.isModuleFocused = false;
      this.moduleOrChapter = 1;
      this.moduleOrChapterId = theItem.value;
      this.getChapterByModuleId(theItem.value);
      
    },
    selectChapter(theItem) {
      this.apiLoadedVideo = false;
      this.selectedChapter = theItem;
      this.inputValueChapter = "";
      this.isChapterFocused = false;
      this.moduleOrChapter = 2;
      this.moduleOrChapterId = theItem.value;
      this.getVideos();
    },
    selectVideo(theItem) {
      this.selectedVideo = theItem;
      this.inputValueVideo = "";
      this.isVideoFocused = false;
    },
    itemVisible(item, inputValue) {
      let currentName = item.name?.toLowerCase();
      let currentInput = inputValue.toLowerCase();
      return currentName?.includes(currentInput);
    },
    onCourseClick() {
      if (this.isCourseFocused) {
        this.isCourseFocused = false;
      } else {
        this.isCourseFocused = true;
      }
      this.isModuleFocused = false;
    },
    onModuleClick() {
      if (this.isModuleFocused) {
        this.isModuleFocused = false;
      } else {
        this.isModuleFocused = true;
      }
    },
    onChapterClick() {
      if (this.isChapterFocused) {
        this.isChapterFocused = false;
      } else {
        this.isChapterFocused = true;
      }
    },
    onSessionClick() {
      if (this.isSessionFocused) {
        this.isSessionFocused = false;
      } else {
        this.isSessionFocused = true;
      }
    },
    setDelayHours() {
      this.quiz.result_delay_in_hours = 0;
    },
    validateStartDate() {
      let current_date = moment();
      let session_start_time = moment(this.session.start_time);
      if (session_start_time < current_date) {
        alert("Incorrect Start Date");
        this.session.start_time = "YYYY-MM-DDThh:mm";
      }
    },
    validateEndDate() {
      if (this.session.end_time < this.session.start_time) {
        alert("Incorrect End Date");
        this.session.end_time = "YYYY-MM-DDThh:mm";
      }
    },
    check() {
      let status = true;
      Object.keys(this.callcheck).forEach((key) =>
        status === !this.callcheck[key] ? false : status
      );
      return status;
    },
    fileDrop(event) {
      event.preventDefault();
      var input = document.createElement("input");
      input.type = "file";
      input.accept = ".csv";
      input.onchange = this.inputChangeHandler;
      input.files = event.dataTransfer.files;
      this.inputChangeHandler({ target: { files: input.files } });
    },
    async inputChangeHandler(e) {
      if (parseInt(e.target.files.length) > 1) {
        this.$toast.warning("You can only upload a single CSV file");
      } else {
        this.firstWindowHandler(5);
        this.percentCompleted = 0;
        this.uploadingFile = true;
        this.csvFileName = e.target.files[0].name;
        var formData = new FormData();
        formData.append("file", e.target.files[0]);
        const upload = await quizService.uploadCsv(formData);
        const fileLink = upload.data.data.fileLink;
        this.percentCompleted = 20;
        this.questions = [];
        this.warnings = [];
        this.errors = [];
        csvSocketinit(
          fileLink,
          this.createdQuizId,
          (progress) => (this.percentCompleted = progress + 20),
          (data) => {
            this.uploadingFile = false;
            this.uploadingCSV = false;
            this.getCSVUploadResults({ data: { data: data } });
          },
          (error) => {
            this.$toast.error(error);
            this.percentCompleted = 0;
          }
        );
      }
    },
    enableHours(val) {
      if (val == 1) {
        this.disable1 = false;
      }
      if (val == 0) {
        this.disable1 = true;
      }
    },

    onlyNumberValidation($event) {
      //console.log($event.keyCode); //keyCodes value
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        // 46 is dot
        $event.preventDefault();
      }
    },
  },
  updated() {
    if (this.session.start_time != this.sessionOld.start_time) {
      this.sessionOld.start_time = this.session.start_time;
    }
    if (this.session.end_time != this.sessionOld.end_time) {
      if (this.session.end_time < this.session.start_time) {
        alert("Incorrect End Date");
        this.session.end_time = "YYYY-MM-DDThh:mm";
      } else {
        this.sessionOld.end_time = this.session.end_time;
      }
    }
  },
};
</script>

<style src="./create-quiz.css" scoped></style>
