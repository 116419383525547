<template src="./edit-quiz-questions.html"></template>

<script>
import editQuizSingleQuestion from "./edit-quiz-single-question/edit-quiz-single-question";
import section from "./add-section/add-section";
import paragraph from "./add-paragraph/add-paragraph";
import quizsummary from "./quiz-summary/quiz-summary";
import quizStats from "./../quiz-stats/quiz-stats";
import tagService from "../../services/tag-service";
import storageService from "../../services/storage-service";
import videoService from "../../services/video-service";
import quizService from "../../services/quiz-service";
import sessionService from "../../services/session-service";
import ClickOutside from "vue-click-outside";
import { saveQuestionSocketInit } from "../../services/save-question-service";
import _ from "lodash";

var paragraph_;
var section_;
var quizSummary;
var popup;
var popupType = 1;
var stats = false;

export default {
  name: "app-edit-quiz-questions",
  directives: {
    ClickOutside,
  },
  components: {
    "app-quiz-summary": quizsummary,
    "app-edit-quiz-single-question": editQuizSingleQuestion,
    "app-quiz-stats": quizStats,
    "app-add-paragraph": paragraph,
    "app-add-section": section,
  },
  props: {
    quizId: {
      type: String,
    },
    quizType: {
      type: String,
    },
    quiz: {
      type: Object,
    },
    instructorId: {
      type: String,
    },
    ai: {
      type: String,
    },
    questions: {
      type: Array,
    },
  },
  data: function () {
    return {
      percentCompleted: 0,
      dataValidated: false,
      reports: [],
      errors: [],
      ommissions: [],
      section_,
      paragraph_,
      quizSummary,
      popup,
      videos: [],
      popupType,
      stats,
      tags: [],
      storageId: "questions",
      moreOptions: false,
      componentKey: 0,
      question: {
        quiz_id: "",
        question: "",
        equation: "",
        image_url: "",
        topic: "",
        tags: [],
        type: 'quiz',
        related_video_id: "",
        related_video: { name: "Link to video" },
        weightage: 0,
        self_assigned_weightage: false,
        solution: "",
        solution_equation: "",
        solution_image_url: "",
        allow_ocr: true,
        options: this.$route.query.quizType === 'daq' ? null : [
          {
            text: "",
            equation: "",
            is_correct: false,
            image_url: "",
          },
          {
            text: "",
            equation: "",
            is_correct: false,
            image_url: "",
          },
          {
            text: "",
            equation: "",
            is_correct: false,
            image_url: "",
          },
          {
            text: "",
            equation: "",
            is_correct: false,
            image_url: "",
          },
        ],
      },
      questionSelected: false,
      selectedQuestionIndex: -1,
      pageno: 1,
      totalPages: 1,
      questionsToDisplay: [],
      newVersionCallBack: [],
    };
  },
  watch: {
    questions(val, preVal) {
      this.totalPages =
        this.questions.length / 10 >= 1
          ? Math.ceil(this.questions.length / 10)
          : 1;
      if (this.totalPages < this.pageno) this.changePage(this.pageno - 1);
      else this.changePage(this.pageno);
    },
  },
  mounted() {
    this.quizId = this.$route.query.quizId;
    this.quizType = this.$route.query.quizType;
    this.instructorId = this.$route.query.instructorId;
    this.ai = this.$route.query.ai;
    this.storageId = `questions-${this.quizId}-${this.instructorId}`;
    if (this.$router.history.current.path == "/quiz/quiz-stats") {
      this.stats = true;
    }
    this.updateLocalStorage();
    this.getAllTags();
    this.getVideos();
    this.totalPages =
      this.questions.length / 10 >= 1
        ? Math.ceil(this.questions.length / 10)
        : 1;
    if (this.questions.length >= 10) {
      this.questionsToDisplay = [...this.questions].splice(0, 10);
    } else {
      this.questionsToDisplay = [...this.questions].splice(
        0,
        this.questions.length
      );
    }
    console.log("questions----",this.questions);
  },
  methods: {
    navigateQuestion(id) {
      this.quizSummary = false;
      this.questionSelected = true;
      this.questionsToDisplay = [this.questions[Number(id.split("-")[0])]];
      this.pageno = this.totalPages + 1;
      this.selectedQuestionIndex = Number(id.split("-")[0]);
    },
    changePage(pageNo) {
      this.questionsToDisplay = [];
      this.questionSelected = false;
      this.pageno = pageNo;
      if (this.questions.length - (pageNo * 10 - 10) >= 10) {
        this.questionsToDisplay = [...this.questions].splice(
          pageNo * 10 - 10,
          10
        );
      } else {
        this.questionsToDisplay = [...this.questions].splice(
          pageNo * 10 - 10,
          this.questions.length - (pageNo * 10 - 10)
        );
      }
      this.$forceUpdate();
    },
    updateLocalStorage() {
      if (this.questions) {
        if (!this.questions.length || this.questions.length===0) {
          this.questions = [];
          this.question.quiz_id = this.quizId;
          this.questions.push(_.cloneDeep(this.question));
        } else {
            this.questions.forEach((elm) => {
              elm.quiz_id = this.quizId;
              if (elm._id) delete elm._id;
            });
        }
        storageService.set(this.storageId, {
          quiz: this.quiz,
          questions: this.questions.length>0?this.questions:[],
        });
      } else {
        const { questions, quiz } = storageService.get(this.storageId);
        this.questions = questions;
        this.quiz = quiz;
      }
      this.validateQuestions();
    },
    moreOptionsHandler() {
      this.moreOptions = !this.moreOptions;
    },
    moreOptionsClose() {
      this.moreOptions = false;
    },
    validateQuestions() {
      try {
        // alert('validate')
        let errors = [];
        let ommissions = [];
        let questions = [];
        let data = storageService.get(this.storageId);
        if (data && data.questions) {
          questions = data.questions;
          questions.forEach((question, index) => {
            if (
              question.equation === undefined ||
              question.equation === "" ||
              question.equation === " "||
               !question.equation.replace(`\\`, "").trim()
            ) {
              if (
                question.question === undefined ||
                question.question === "" ||
                question.question === " "
              ) {
                this.questions[
                  index
                ].error = `Question text can not be empty at Question # ${
                  index + 1
                }`;
                errors.push(this.questions[index].error);
              }
            }
            if(question.type === 'quiz' || question.type === 'drag_and_drop' || question.type === 'mix_and_match'){
              if (question.options && question.options.length) {
                let oneOptionIsCorrect = 0;
                question.options.forEach((option, indx) => {
                  if(question.type === 'mix_and_match') { 
                    option.is_correct = option.correct_answer;
                  }
                  if (
                    (option.equation === undefined ||
                      option.equation === "" ||
                      option.equation === " "||
                    !option.equation.replace(`\\`, "").trim())
                  ) {
                    if (
                      option.option === undefined ||
                      option.option === "" ||
                      option.option === " "
                    ) {
                      this.questions[
                        index
                      ].error = `Option text can not be empty at option # ${
                        indx + 1
                      } at Question # ${index + 1}`;
                      errors.push(this.questions[index].error);
                    }
                  }
                  if (option.is_correct === true) {
                    oneOptionIsCorrect += 1;
                  }
                  if (indx + 1 === question.options.length) {
                    if (oneOptionIsCorrect === 0 && question.type !== 'mix_and_match') {
                      this.questions[
                        index
                      ].error = `Correct option is required at Question # ${
                        index + 1
                      }`;
                      errors.push(this.questions[index].error);
                    } else if (oneOptionIsCorrect > 1 && question.type !== 'mix_and_match') {
                      this.questions[
                        index
                      ].error = `Only one option should be marked as true at Question # ${
                        index + 1
                      }`;
                      errors.push(this.questions[index].error);
                    }
                  }
                });
              }
            }
            else if((question.type === 'fill_in_the_blank') && !question.solution) {
              errors.push(`Question must have a valid solution`);
            }
            else if(question.type === 'fill_in_the_blank' && !question.question.includes('__BLANK__')) {
              errors.push(`Question must have a valid blank signature (__BLANK__)`);
            }
            else if(question.type !== 'quiz' && question.type !== 'drag_and_drop' && question.type !== 'mix_and_match' && question.type !== 'fill_in_the_blank') errors.push(`Question must have a valid type`);
            if (question.tags && !question.tags.length) {
              this.questions[
                index
              ].ommission = `Minimum one tag should be added at Question # ${
                index + 1
              }`;
              ommissions.push(this.questions[index].ommission);
            }
          });

          this.errors = errors;
          // if(!this.errors.length) this.questions = questions;
          this.ommissions = ommissions;
        }
      } catch (error) {
        console.error(error);
      }
    },
    shuffle(array) {
      var currentIndex = array.length,
        temporaryValue,
        randomIndex;

      // While there remain elements to shuffle...
      while (0 !== currentIndex) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;

        // And swap it with the current element.
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
      }

      return array;
    },
    randomizeIndexes() {
      this.questions = this.shuffle(this.questions);
      if (this.questions.length >= 10) {
        this.questionsToDisplay = [...this.questions].splice(0, 10);
      } else {
        this.questionsToDisplay = [...this.questions].splice(
          0,
          this.questions.length
        );
      }
      this.forceRerender();
      this.updateLocalStorage();
      // this.$router.go()
    },
    forceRerender() {
      this.componentKey += 1;
    },
    moveQuestionUp(index) {
      if (this.questions.length > 1) {
        if (index !== 0) {
          let temp = this.questions[index - 1];
          this.questions[index - 1] = this.questions[index];
          this.questions[index] = temp;
          this.forceRerender();
          this.updateLocalStorage();
        }
      }
    },
    moveQuestionDown(index) {
      if (this.questions.length > 1) {
        if (index !== this.questions.length - 1) {
          let temp = this.questions[index + 1];
          this.questions[index + 1] = this.questions[index];
          this.questions[index] = temp;
          this.forceRerender();
          this.updateLocalStorage();
        }
      }
    },
    deleteQuestion(index) {
      this.questions.splice(index, 1);
      this.forceRerender();
      this.updateLocalStorage();
    },
    async getAllTags() {
      const response = await tagService.getAllTags();
      if (response) {
        this.tags = [];
        response.data.data.tag.forEach((elm) => {
          this.tags.push({ id: elm._id, text: elm.tag_name });
        });
      }
    },
    addQuestion() {
      this.questions.push(_.cloneDeep(this.question));
      this.updateLocalStorage();
    },
    addParagraphSection(type) {
      if (type == "para") {
        this.paragraph_ = !this.paragraph_;
      } else if (type == "sec") {
        this.section_ = !this.section_;
      }
    },
    async getVideos() {
      let params = {
        id: this.$route.query.id,
        instructor_id: this.instructorId,
        type: this.$route.query.type,
      };
      try {
        let response = await videoService.getVideos(params);
        if (response) {
          if (response.data.success) {
            this.videos = response.data.videos;
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    async checkPublication() {
      try {
        this.percentCompleted = 0;
        if (this.errors.length) {
          throw new Error("Please resolve the errors first");
        }
        let body = {
          questions: this.questions,
          is_published: true,
        };
        this.dataValidated = true;
        this.popupType = 4;
        this.popup = true;
        saveQuestionSocketInit(
          { questions: this.questions, isPublished: true },
          (newVersion) => {
            this.popupType = 1;
            this.popup = true;
            this.newVersionCallBack = [];
            this.newVersionCallBack.push(newVersion);
          },
          () => {
            storageService.set(this.storageId, null);
            this.$toast.success("Published");
            // this.$router.replace({ name: "app-quiz-landing" });
            this.popupType = 5;
          },
          (error) => {
            throw new Error(error);
          },
          async (data) => {
            storageService.set(this.storageId, null);
            this.$toast.success("Published");
            // this.$router.replace({ name: "app-quiz-landing" });
            this.popup = false;
            this.popupType = 5;
            let session = data.session;
            delete session._id;
            session.quiz_id = data.quiz._id;
            await sessionService.createSession(session);
          },
          (p) => (this.percentCompleted = p)
        );
      } catch (error) {
        if (error.message) {
          this.$toast.error(error.message);
        } else {
          this.$toast.error(error);
        }
      }
    },
    async createNewVersion() {
      try {
        if (this.errors.length) {
          throw new Error("Please resolve the errors first");
        }
        this.newVersionCallBack[this.newVersionCallBack.length - 1]();
      } catch (error) {
        if (error.message) {
          this.$toast.error(error.message);
        } else {
          this.$toast.error(error);
        }
      }
    },
    closePopUp() {
      // this.quizSummary = false;
    },
    goToQuiz(){
      let link;
      if(this.quizType === "daq"){
        link = `${process.env.VUE_APP_SHARE_ABLE_LINK_POP}course/${this.quiz.module.index_url}/${this.quiz.module_id}/${this.quiz._id}?daq=true&daq_id=${this.quiz._id}&courseIndexUrl=${this.quiz.course.index_url}`
      }else{
        link = process.env.VUE_APP_SHARE_ABLE_LINK + this.quizId
      }
      window.open(link, '_blank');
      this.popup = false;
      this.$router.replace({ name: "app-quiz-landing" });
    },
    goToLanding(){
      this.$router.replace({ name: "app-quiz-landing" });
      this.popup = false;
    }
  },
};
</script>

<style src="./edit-quiz-questions.css" scoped></style>
